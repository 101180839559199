import config from '@/config/env-constants';
import _ from 'lodash';


function getDefaultStorageLocationObj() {
	return {
		name: '',
		description: '',
		company: null,
		companyId: '',
		address: '',
		geoaddress: {
			latitude: null,
			longitude: null,
		},
		areaRadius: config.areaRadiusDefaultValue,
		isActive: 'true',
		dateCreated: '',
		createdBy: '',
		dateUpdated: '',
		updatedBy: '',
	};
}

function exceedMaximum(company, storageLocations, currLocation) {
	let maxNo = company.noOfStorageLocations ? company.noOfStorageLocations : 0;
	if (!_.isEmpty(storageLocations)) {
		let activeLocations = _.filter(storageLocations, o => {
			return o.isActive === 'true' && company.id === o.companyId;
		});

		let activeLocationsIds = _.map(activeLocations, 'id');
		if (currLocation && activeLocationsIds.includes(currLocation.id)) {
			// skip if the currLocation is already part of current active locations
			return false;
		} else {
			return _.size(activeLocations) >= maxNo;
		}
	}
	return false;
}

function cleanupFields(storageLocation) {
	let cleanedObj = { ...storageLocation };

	delete cleanedObj['status'];
	delete cleanedObj['Date Created'];
	delete cleanedObj['Date Updated'];
	delete cleanedObj['_showDetails'];

	return cleanedObj;
}

export const StorageLocationUtil = {
	getDefaultStorageLocationObj,
	exceedMaximum,
	cleanupFields
}
