<template>
	<b-modal
		title="Storage Location"
		:id="'storage-location-view-' + storageLocation.id"
		:no-close-on-backdrop="true"
		@shown="refreshMap"
		size="lg"
		ok-only
		centered
	>
		<b-row>
			<b-col sm="12">
				<GmapMap
					:center="coordinates"
					:zoom="12"
					:style="{ width: '100%', height: '400px' }"
				>
					<GmapMarker
						:position="coordinates"
						:clickable="true"
						@click="infoMarkerToggle = !infoMarkerToggle"
					>
						<gmap-info-window :opened="infoMarkerToggle">
							Storage Name: <b>{{ details.name }}</b>
							<br />
							Address: <b>{{ details.address }}</b>
						</gmap-info-window>
					</GmapMarker>
				</GmapMap>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import { LocationUtil } from '@/utils/locationUtil';

export default {
	name: 'storage-location-view',
	props: {
		storageLocation: {
			type: Object,
			required: true,
		},
	},
	data() {
		return { infoMarkerToggle: false };
	},
	computed: {
		coordinates() {
			const coordinates = this.storageLocation.geoaddress;
			if (coordinates) {
				return {
					lat: LocationUtil.getLatitude(coordinates),
					lng: LocationUtil.getLongitude(coordinates),
				};
			} else return {};
		},
		details() {
			const details = this.storageLocation;
			return {
				name: details.name,
				address: details.address,
			};
		},
	},
	methods: {
		refreshMap() {
			this.$gmapDefaultResizeBus.$emit('resize');
			this.infoMarkerToggle = false;
		},
	},
};
</script>